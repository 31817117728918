import dynamic from 'next/dynamic';
import { useSelector } from 'react-redux';
import { selectCurrentPopup } from '@/redux/popup-manager/selectors';

const PopupMangerContent = dynamic(() => import('./PopupMangerContent'));

export default function PopupManager() {
  const currentPopup = useSelector(selectCurrentPopup);

  if (!currentPopup) return null;

  return <PopupMangerContent />;
}
