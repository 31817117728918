import { notifyError } from '@/src/shared/utils/message';
import type { AxiosResponse } from 'axios';
import { callPostApi } from '@/src/core/utils/api';
import {
  FAILURE_USER_REGISTER,
  FAILURE_USER_REGISTER_EMAIL,
  REQUEST_USER_REGISTER,
  SUCCESS_USER_REGISTER,
} from './actionTypes';
import {
  FailureUserRegisterAction,
  FailureUserRegisterFieldAction,
  RequestUserRegisterAction,
  SuccessUserRegisterAction,
  UserRegisterThunkDispatch,
  UserRegisterThunkResult,
} from './types';
import UTM from '../../core/UTM';
import { AnalyticApis } from '@/pages/api/analytics';
import dayjs from '@/src/core/utils/dayjs';

export const requestUserRegister = (): RequestUserRegisterAction => ({
  type: REQUEST_USER_REGISTER,
});

export const successUserRegister = (
  result: string
): SuccessUserRegisterAction => ({
  type: SUCCESS_USER_REGISTER,
  payload: {
    result,
  },
});

export const failureUserRegister = (
  error: string
): FailureUserRegisterAction => ({
  type: FAILURE_USER_REGISTER,
  payload: {
    error,
  },
});

export const failureUserRegisterField = (
  error_field: false | 'email'
): FailureUserRegisterFieldAction => ({
  type: FAILURE_USER_REGISTER_EMAIL,
  payload: {
    error_field,
  },
});

/**
 * @deprecated Please use userRegister instead
 * */
export const userRegister_DEPRECATED =
  (
    source: string,
    fullName: string,
    email: string,
    is_agent: boolean,
    brokerage: string,
    agentArea: string,
    videoId?: number,
    gRecaptcha?: string,
    redirectUrl?: string,
    query?: any,
    referrer?: string,
    UserTypeId?: number,
    mls_id?: string,
    zone_id?: string,
    ads_region?: string,
    ads_city?: string,
    listingId?: string
  ): UserRegisterThunkResult =>
  async (dispatch: UserRegisterThunkDispatch) => {
    await dispatch(
      userRegister({
        source,
        referrer,
        UserTypeId,
        brokerage,
        email,
        is_agent,
        agentArea,
        fullName,
        ads_city,
        ads_region,
        listingId,
        mls_id,
        query,
        videoId,
        zone_id,
        redirectUrl,
        gRecaptcha,
      })
    );
  };

export type RegisterType = 'google' | 'facebook';

export interface UserRegisterBody {
  source: string;
  register_type?: RegisterType;
  fullName: string;
  email: string;
  is_agent: boolean;
  brokerage: string;
  agentArea?: string;
  videoId?: number;
  gRecaptcha?: string;
  redirectUrl?: string;
  query?: any;
  referrer?: string;
  UserTypeId?: number;
  mls_id?: string;
  zone_id?: string;
  ads_region?: string;
  ads_city?: string;
  listingId?: string;
  caption?: string;
  phone?: string;
  otp_token?: string;
  active_board?: string;
  callbacks?: {
    onError?: (error: string) => void;
    onSuccess?: () => void;
  };
}

export const userRegister =
  (body: UserRegisterBody): UserRegisterThunkResult =>
  async (dispatch: UserRegisterThunkDispatch) => {
    try {
      dispatch(requestUserRegister());
      const {
        source,
        register_type,
        referrer,
        UserTypeId,
        brokerage,
        email,
        is_agent,
        agentArea,
        fullName,
        ads_city,
        ads_region,
        listingId,
        mls_id,
        query,
        videoId,
        zone_id,
        redirectUrl,
        gRecaptcha,
        caption,
        phone,
        active_board,
      } = body;
      const utmQuery = UTM.getUTMs();

      const data = {
        source: source,
        register_type,
        fullName: fullName,
        email: email,
        is_agent: is_agent,
        brokerage: brokerage,
        'g-recaptcha-response': gRecaptcha,
        utm_source: utmQuery?.utm_source,
        utm_medium: utmQuery?.utm_medium,
        utm_campaign: utmQuery?.utm_campaign,
        utm_content: utmQuery?.utm_content,
        referrer: referrer ?? document.referrer,
        page_location: window.location.pathname,
        user_type_id: UserTypeId,
        phone,
        active_board,
        caption,
        listingId,
      };

      if (agentArea) data['agentArea'] = agentArea;
      if (mls_id) data['mls_id'] = mls_id;
      if (zone_id) data['zone_id'] = zone_id;
      if (videoId) data['video_id'] = videoId;
      if (ads_region) data['ads_region'] = ads_region;
      if (ads_city) data['ads_city'] = ads_city;
      if (query) data['query'] = query;

      const response: AxiosResponse<{
        result: string;
        error: boolean;
        fbEventId: string;
        extenralId: string;
        user_id?: string | number;
      }> = await callPostApi('/landing/same-domain-register', data, {
        timeout: 15000,
      });

      if (response.data.error) {
        notifyError(response.data.result);
        dispatch(failureUserRegister(response.data.result));

        if (response?.data?.result?.indexOf('email') > -1)
          dispatch(failureUserRegisterField('email'));
      } else {
        // @ts-ignore fbq
        if (response.data.fbEventId && typeof fbq === 'function') {
          // @ts-ignore fbq
          fbq(
            'trackCustom',
            'register',
            {},
            {
              eventID: response.data.fbEventId,
            }
          );

          // @ts-ignore fbq
          fbq(
            'track',
            'CompleteRegistration',
            {
              content_name: 'register',
              status: 'registered',
            },
            {
              eventID: `CompleteRegistration-${response.data?.extenralId}`,
            }
          );
        }
        AnalyticApis.gaEvent('registrationSuccess', {
          user_id: response.data?.user_id,
          registration_date: dayjs().format('YYYY-MM-DD'),
          page_location: window?.location?.pathname,
        });
        const apiRedirectUrl = response.data.result ?? '';
        if (source == 'from-affiliate') {
          window.location.href = apiRedirectUrl;
        } else if (apiRedirectUrl.includes('/login')) {
          window.location.replace(apiRedirectUrl);
        } else {
          window.location.replace(redirectUrl || apiRedirectUrl);
        }
        dispatch(successUserRegister(apiRedirectUrl));
      }
    } catch (e) {
      console.log(e);
      AnalyticApis.gaEvent('registered_failed', {
        error: e?.toString(),
        page_location: window?.location?.pathname,
      });
      dispatch(failureUserRegister('Fail to register user'));
      notifyError(e.response?.data?.result || 'Failed, please try again');
    }
  };

/**
 * Registers a user with their phone number and verifies their phone during registration.
 * @param {UserRegisterBody} body - The data of the user to be registered.
 * @returns {Promise<void>} A promise that resolves if the user is successfully registered and verified.
 * @throws {Error} Throws an error if any of the parameters are missing or invalid.
 */
export const userRegisterV2 =
  (body: UserRegisterBody): UserRegisterThunkResult =>
  async (dispatch: UserRegisterThunkDispatch) => {
    dispatch(requestUserRegister());
    try {
      const {
        source,
        referrer,
        UserTypeId,
        brokerage,
        email,
        is_agent,
        agentArea,
        fullName,
        ads_city,
        ads_region,
        listingId,
        mls_id,
        query,
        videoId,
        zone_id,
        redirectUrl,
        gRecaptcha,
        caption,
        phone,
        active_board,
        otp_token,
      } = body;
      const utmQuery = UTM.getUTMs();

      const data = {
        source: source,
        fullName: fullName,
        is_agent: is_agent,
        brokerage: brokerage,
        'g-recaptcha-response': gRecaptcha,
        utm_source: utmQuery?.utm_source,
        utm_medium: utmQuery?.utm_medium,
        utm_campaign: utmQuery?.utm_campaign,
        utm_content: utmQuery?.utm_content,
        referrer: referrer ?? document.referrer,
        page_location: window.location.pathname,
        user_type_id: UserTypeId,
        active_board,
        caption,
        listingId,
      };

      if (email) data['email'] = email;
      if (phone) data['phone'] = phone;
      if (otp_token) data['otp_token'] = otp_token;
      if (agentArea) data['agentArea'] = agentArea;
      if (mls_id) data['mls_id'] = mls_id;
      if (zone_id) data['zone_id'] = zone_id;
      if (videoId) data['video_id'] = videoId;
      if (ads_region) data['ads_region'] = ads_region;
      if (ads_city) data['ads_city'] = ads_city;
      if (query) data['query'] = query;

      const response: AxiosResponse<{
        data: {
          result: string;
          error: boolean;
          fbEventId: string;
          extenralId: string;
        };
      }> = await callPostApi('/v1/landing/signup/verify-token', data, {
        timeout: 15000,
      });

      if (response?.data?.data?.error) {
        notifyError(response?.data?.data?.result);
        dispatch(failureUserRegister(response?.data?.data?.result));

        if (response?.data?.data?.result?.indexOf('email') > -1)
          dispatch(failureUserRegisterField('email'));
      } else {
        // @ts-ignore fbq
        if (response.data.fbEventId && typeof fbq === 'function') {
          // @ts-ignore fbq
          fbq(
            'trackCustom',
            'register',
            {},
            {
              eventID: response?.data?.data?.fbEventId,
            }
          );

          // @ts-ignore fbq
          fbq(
            'track',
            'CompleteRegistration',
            {
              content_name: 'register',
              status: 'registered',
            },
            {
              eventID: `CompleteRegistration-${response.data?.data?.extenralId}`,
            }
          );
        }
        const apiRedirectUrl = response?.data?.data?.result ?? '';
        dispatch(successUserRegister(apiRedirectUrl));
        if (source == 'from-affiliate') {
          window.location.href = apiRedirectUrl;
        } else if (apiRedirectUrl.includes('/login')) {
          window.location.replace(apiRedirectUrl);
        } else {
          window.location.replace(redirectUrl || apiRedirectUrl);
        }
        if (body?.callbacks?.onSuccess) {
          body?.callbacks?.onSuccess();
        }
      }
    } catch (error) {
      AnalyticApis.gaEvent('registered_failed', {
        error: error?.toString(),
        page_location: window?.location?.pathname,
      });
      dispatch(
        failureUserRegister(
          error.response?.data?.message ?? 'Fail to register user'
        )
      );
      if (body?.callbacks?.onError) {
        body?.callbacks?.onError(error.response?.data?.message);
      } else {
        notifyError(
          error.response?.data?.message ?? 'Failed, please try again'
        );
      }
    }
  };
