export const linksData = [
  {
    title: 'Company',
    links: [
      {
        page: 'About',
        url: '/about',
      },
      {
        page: 'Careers',
        url: 'https://careers.roomvu.com/',
        external: true,
      },
      {
        page: 'Contact Us',
        url: '/contact-us',
      },
      {
        page: 'Partners',
        url: '/partners',
      },
    ],
  },
  {
    title: 'Resources',
    links: [
      {
        page: 'Roomvu Reviews',
        url: '/testimonial',
      },
      {
        page: 'Roomvu Blog',
        url: '/blog',
      },
      {
        page: 'Press',
        url: '/press',
      },
      {
        page: 'Roomvu Academy',
        url: '/academy',
      },
    ],
  },
  {
    title: 'Legal',
    links: [
      {
        page: 'Privacy Policy',
        url: '/privacy-policy',
      },
      {
        page: 'Terms & Conditions',
        url: '/terms-of-service',
      },
      {
        page: 'DMCA Notice',
        url: '/dmca-notice',
      },
    ],
  },
];
