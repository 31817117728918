import React from 'react';
import styles from './links.module.scss';
import Link from 'next/link';

const Links = ({ title, links }) => {
  return (
    <div className={styles.links}>
      <div className={styles.title}>{title}</div>
      {links.map((link) =>
        !link.external ? (
          <Link className={styles.link} key={link.page} href={link.url}>
            {link.page}
          </Link>
        ) : (
          <a
            className={styles.link}
            key={link.page}
            href={link.url}
            rel="noopener noreferrer"
            target="_blank"
          >
            {link.page}
          </a>
        )
      )}
    </div>
  );
};

export default Links;
