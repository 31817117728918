import { GoogleOAuthProvider, useGoogleOneTapLogin } from '@react-oauth/google';
import { getAPIUrl } from '../../utils/api';
import { notifyError } from '@/src/shared/utils/message';
import axios from 'axios';
import { GeoLocationResult } from '@/src/containers/Landing/utils/geoVideo/types';
import { useDispatch } from 'react-redux';
import { RegisterType, userRegister } from '@/redux/userRegister/actions';
import { IVideo } from '@/types/video';
import { Pages } from '@/types/pages';
import { useEventProvider } from '@/helpers/event-context';

interface Props {
  video: IVideo;
  source: Pages;
  detectedRegion: GeoLocationResult['detectedRegion'];
}

const client_id =
  '603609326642-duielvhc4r4k8euui922585tua36g3ej.apps.googleusercontent.com';

const GoogleOneTapLogin = ({ detectedRegion, source, video }: Props) => {
  const dispatch = useDispatch();
  const { sendEvent } = useEventProvider();

  useGoogleOneTapLogin({
    promptMomentNotification: (prompt: any) => {
      // "h": Determines if the prompt has been shown to the user or not.
      if (prompt?.h) {
        sendEvent('google_signin_popup_shown');
      }
    },
    onSuccess: (credentialResponse) => {
      loginWithGoogle(credentialResponse.credential);
      sendEvent('google_signin_popup_clicked');
    },
  });

  const loginWithGoogle = async (tokenId: string) => {
    try {
      const response = await axios.get(
        getAPIUrl() + '/3rd-party-login/user-info',
        {
          params: {
            type: 'google',
            id_token: tokenId,
          },
        }
      );

      if (response.data.error) throw 'Api has error';

      if (
        !response.data.result.is_registered &&
        response.data.result.can_register
      ) {
        const name = response.data.result.name;
        const email = response.data.result.email;
        const region = detectedRegion;
        if (region) {
          handleOnLogin(name, email, true, '', region, null, 'google');
        } else {
          handleOnLogin(name, email, true, '', '', null, 'google');
        }
      } else if (response.data.result.is_registered) {
        window.location = response.data.result.login_url;
      }

      sendEvent('google_signin_popup_successful');
    } catch (err) {
      console.log(err);
      notifyError('Please try again');
    }
  };

  const handleOnLogin = (
    fullName: string,
    email: string,
    is_agent: boolean,
    brokerage: string,
    agentArea: string,
    gRecaptcha: string,
    register_type: RegisterType
  ) => {
    dispatch(
      userRegister({
        source,
        fullName,
        email,
        is_agent,
        brokerage,
        agentArea,
        videoId: video?.id,
        gRecaptcha,
        register_type,
      })
    );
  };

  return null;
};

const GoogleOneTap = (props: Props) => (
  <GoogleOAuthProvider clientId={client_id}>
    <GoogleOneTapLogin {...props} />
  </GoogleOAuthProvider>
);

export default GoogleOneTap;
