import React from 'react';
import styles from './leftSection.module.scss';
import Image from '@/src/shared/components/Image';

const LeftSection = () => {
  return (
    <div className={styles.leftSection}>
      <Image
        src="/images/landing/roomvu-tm-white-logo.svg"
        alt="roomvu-logo"
        width={173}
        height={23}
      />
      <div className={styles.address}>
        2 Embarcadero Center, 8th Floor, San Francisco CA 94111
      </div>
      <div className={styles.socials}>
        <a
          href="https://www.facebook.com/roomvu/"
          rel="noopener noreferrer"
          target="_blank"
        >
          <Image
            src="/images/social/facebook-grey.svg"
            alt="facebook"
            width={24}
            height={24}
          />
        </a>
        <a
          href="https://www.instagram.com/roomvu.co/"
          rel="noopener noreferrer"
          target="_blank"
        >
          <Image
            src="/images/social/instagram-grey.svg"
            alt="instagram"
            width={24}
            height={24}
          />
        </a>
        <a
          href="https://www.linkedin.com/company/18255209/"
          rel="noopener noreferrer"
          target="_blank"
        >
          <Image
            src="/images/social/linkedin-grey.svg"
            alt="linkedin"
            width={24}
            height={24}
          />
        </a>
        <a
          href="https://www.youtube.com/channel/UCWx0BoVWtR-JIO8cY-W1S1A"
          rel="noopener noreferrer"
          target="_blank"
        >
          <Image
            src="/images/social/youtube-grey.svg"
            alt="youtube"
            width={24}
            height={24}
          />
        </a>
      </div>
    </div>
  );
};

export default LeftSection;
