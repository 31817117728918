import React from 'react';
import styles from './index.module.scss';
import { linksData } from './components/linksData';
import Links from './components/Links';
import LeftSection from './components/LeftSection';
import BottomSection from './components/BottomSection';
import MobileLinks from './components/MobileLinks';
import { useIsMobile } from '@/src/shared/hooks/utils/useIsMobile';

const Footer = () => {
  const isMobile = useIsMobile();
  return (
    <footer className={styles.footer}>
      <div className={styles.topSection}>
        <LeftSection />
        <div className={styles.rightSection}>
          {linksData.map((link) =>
            isMobile ? (
              <MobileLinks
                title={link.title}
                links={link.links}
                key={link.title}
              />
            ) : (
              <Links title={link.title} links={link.links} key={link.title} />
            )
          )}
        </div>
      </div>
      <BottomSection />
    </footer>
  );
};

export default Footer;
