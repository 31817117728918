import { clsx } from 'clsx';
import { TypographyProps } from './types';
import styles from './Typography.module.scss';

export function Typography({
  tag: Tag = 'p',
  children,
  className,
  variant,
  fontWeight,
  size,
  ...props
}: TypographyProps) {
  return (
    <Tag
      className={clsx(className, {
        // Variant
        [styles.display]: variant === 'display',
        [styles.text]: variant === 'text',
        [styles.heading]: variant === 'heading',
        [styles.label]: variant === 'label',
        [styles.navLabel]: variant === 'nav-label',
        [styles.body]: variant === 'body',
        // Size
        [styles.xxl]: size === '2xl',
        [styles.xl]: size === 'xl',
        [styles.lg]: size === 'lg',
        [styles.md]: size === 'md',
        [styles.sm]: size === 'sm',
        [styles.xs]: size === 'xs',
        [styles.h1]: size === 'h1',
        [styles.h2]: size === 'h2',
        [styles.h3]: size === 'h3',
        [styles.h4]: size === 'h4',
        [styles.h5]: size === 'h5',
        [styles.h6]: size === 'h6',
        [styles.h7]: size === 'h7',
        [styles.standardPrimary]: size === 'standard-primary',
        [styles.standardSecondary]: size === 'standard-secondary',
        [styles.extraSmallSecondary]: size === 'extra-small-secondary',
        [styles.extraSmallPrimary]: size === 'extra-small-primary',
        [styles.large]: size === 'large',
        [styles.default]: size === 'default',
        [styles.small]: size === 'small',
        // Font weight
        [styles.regular]: fontWeight === 'regular',
        [styles.medium]: fontWeight === 'medium',
        [styles.semibold]: fontWeight === 'semibold',
        [styles.bold]: fontWeight === 'bold',
      })}
      {...props}
    >
      {children}
    </Tag>
  );
}
