import React from 'react';
import styles from './bottomSection.module.scss';
import Image from '@/src/shared/components/Image';
import Link from 'next/link';
import dayjs from '@/src/core/utils/dayjs';

const BottomSection = () => {
  return (
    <div className={styles.bottomSection}>
      <div className={styles.copyright}>
        @{dayjs().year().toString()} Roomvu Technologies Inc. All Rights
        Reserved
      </div>
      <div className={styles.logos}>
        <a
          href="https://www.roomvustore.com/"
          rel="noopener noreferrer"
          target="_blank"
        >
          <Image
            src="/images/footer/roomvu-store.svg"
            width={110}
            height={12}
            alt="roomvu-store"
          />
        </a>
        <Link href="/academy">
          <Image
            src="/images/footer/roomvu-academy.svg"
            width={82}
            height={28}
            alt="roomvu-academy"
          />
        </Link>
        <Image
          src="/images/footer/reach.png"
          width={100}
          height={24}
          alt="reach"
        />
        <a
          href="https://apps.apple.com/us/app/roomvu-real-estate/id1553507315"
          rel="noopener noreferrer"
          target="_blank"
        >
          <Image
            src="/images/footer/app-store.png"
            width={108}
            height={36}
            quality={100}
            alt="app-store"
          />
        </a>
      </div>
    </div>
  );
};

export default BottomSection;
