import React, { useState } from 'react';
import styles from './mobileLinks.module.scss';
import Link from 'next/link';
import { Icon } from '../../Icon';

const MobileLinks = ({ title, links }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <div>
      <div className={styles.title} onClick={handleOpen}>
        {title}
        <Icon color="white" name={open ? 'chevron-up' : 'chevron-down'} />
      </div>
      {open &&
        links.map((link) =>
          !link.external ? (
            <Link className={styles.link} key={link.page} href={link.url}>
              {link.page}
            </Link>
          ) : (
            <a
              className={styles.link}
              key={link.page}
              href={link.url}
              rel="noopener noreferrer"
              target="_blank"
            >
              {link.page}
            </a>
          )
        )}
    </div>
  );
};

export default MobileLinks;
